<template>
  <div
    ref="ModalSendNotificationLive"
    class="modal fade"
    id="ModalSendNotificationLive"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalSendNotificationLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="isLoading">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>{{ $t('lbl_send_notification') }}</span>
          </div>
          <button
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="hodo-el">
            <div class="text-black fw-700 fs-18 required">{{ $t('lbl_title') }}</div>
            <el-input
              :placeholder="$t('lbl_enter_title')"
              v-model="form.title"
              @change="value => form.title = value"
            />
          </div>

          <div class="mt-4">
            <div class="text-black fw-700 fs-18 required">{{ $t('lbl_content') }}</div>
            <el-input
              type="textarea"
              :placeholder="$t('lbl_enter_content')"
              :rows="5"
              v-model="form.content"
              @change="value => form.content = value"
            />
          </div>
        </div>

        <div class="modal-footer flex justify-center">
          <button
            :disabled="isDisabled"
            v-loading="isLoading"
            @click="onSubmit"
            type="button"
            class="btn bg-pri text-white"
          >{{ $t('lbl_button_send') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TiptapEditor from '@/components/Editors/Tiptap'

export default {
  name: 'ModalSendNotification',
  components: { TiptapEditor },
  data () {
    return {
      isDisabled: false,
      isLoading: false,
      form: {
        title: '',
        content: ''
      }
    }
  },
  methods: {
    async onSubmit () {
      if (!this.$route.params.id) return

      try {
        const params = {
          ...this.form,
          circle_id: Number(this.$route.params.id)
        }

        await this.$rf
          .getRequest('DoctorRequest')
          .sendNotificationLiveStream(params)

        const labelSuccess = this.$t('lbl_sent_notification_successfully')

        this.$toast.open({
          message: labelSuccess,
          type: 'success'
        })
        this.form = {
          title: '',
          content: ''
        }
        this.showModalSendNotification(false)
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('lbl_sent_notification_fail'),
          type: 'warning'
        })
      }
    },
    showModalSendNotification (show) {
      window.$('#ModalSendNotificationLive').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
</style>
