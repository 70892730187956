<template>
  <div>
    <div v-if="loading" class="loading-meet-room">
      <img
        src="../../../public/assets/images/gif/Loading.gif"
        width="212"
        height="212"
        alt=""
      />
    </div>
    <div v-if="!loading && email">
      <OTPLoginCpn :prop_email="email" @logged="logged"/>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import OTPLoginCpn from '../../components/Users/OTPLoginCpn.vue'
export default {
  name: 'MagicLinkJoinCircle',
  components: {OTPLoginCpn},
  data () {
    return {
      meet_room: null,
      members: [],
      loading: false,
      is_member: false,
      verified: false,
      login_otp: false,
      otp: '',
      email: ''
    }
  },
  created () {
    this.loading = true
  },
  mounted () {
    console.log(this.$route.query.id, this.$route.query.email)
    if (!this.$route.query.id || !this.$route.query.email) this.backToList()
    const getLocalUser = appUtils.getLocalUser()
    if (getLocalUser) {
      if (this.$route.query.email !== getLocalUser.email) {
        appUtils.removeLocalUser()
        this.email = this.$route.query.email
        this.loading = false
      } else this.$router.push({ name: 'CircleList' })
      // else this.$router.push({name: 'CircleDetail', params: {id: this.$route.query.id}})
    } else {
      this.email = this.$route.query.email
      this.loading = false
    }
  },
  methods: {
    backToList () {
      this.$router.push({ name: 'Home' })
    },
    logged () {
      // window.location.href = `/#/circle/${this.$route.query.id}`
      window.location.href = `/#/circle`
    }
  }
}
</script>

<style scoped>
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.login-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  padding: 16px;
}
.login-form {
  width: 100%;
  max-width: 500px;
}
</style>
